$color-telus-purpleTelus: #4b286d;
$color-telus-purpleDeluge: #7c53a5;
$color-telus-red: #e12339;
$color-telus-redDark: #c12335;
$color-telus-redLight: #fff6f8;
$color-telus-white: #ffffff;
$color-telus-greyAthens: #f4f4f7;
$color-telus-greyMystic: #e3e6e8;
$color-telus-greyCloud: #b2b9bf;
$color-telus-greyThunder: #2c2e30;
$color-telus-greyShuttle: #676e73;
$color-telus-greyCharcoal: #414547;
