.chart-top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;

  // @media (max-width: 700px) {
  //   flex-direction: column;
  //   gap: 1rem;
  // }
}
.rdrCalendarWrapper {
  background-color: $color-telus-white;
  border: 1px solid $color-telus-greyMystic;
  border-radius: 4px;
  margin-top: 0.5rem;
  box-shadow: 1px 2px 6px $color-telus-greyMystic;
}

.download-raw-data {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.dashboard-reporting-main {
  display: flex;
}

.filters {
  width: 350px;
  padding: 12px;
  border-right: 1px solid $color-telus-greyCloud;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-section:not(:last-child) {
  border-bottom: 1px solid $color-telus-greyCloud;
  padding: 16px 0;
}

.filter-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 0;
  min-height: 75px;
  max-height: 200px;
  overflow: scroll;
}

.filter-main-action {
  display: flex;
}

.filter-action {
  display: flex;
  gap: 16px;
  padding: 16px 0;
}

.date-range-main {
  display: flex;
  gap: 16px;
  margin: 20px 0;
}

.date-picker-filter {
  height: 40px;
  padding: 10px;
}

.reports {
  flex-grow: 1;
}

.reporting-nodata {
  display: flex;
  padding: 1rem;
  justify-content: center;
}
