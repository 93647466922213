.header {
  border-bottom: 1px solid $color-telus-greyMystic;

  &__inner {
    margin: 0 auto;
    padding: 1.5rem 1rem;
  }
}

.locale-selector-box {
  display: block;
  position: absolute;
  top: 53px;
  left: -170px;
  padding: 12px 0px;
  width: 260px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(216, 216, 216);
  border-radius: 0px 0px 8px 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 0px;
  z-index: 1600;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  overflow: hidden;
  border-top: 0;

  &__row {
    padding: 10px;
    font-size: 14px;
    color: $color-telus-greyCharcoal;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: 0;
    background: transparent;
    text-align: left;
    cursor: pointer;

    &.active {
      border-left: 3px solid $color-telus-purpleTelus;
    }

    &.link {
      &:hover {
        background-color: #f5f5f5;
        text-decoration: underline;
      }
    }
  }
}

.hamburger-menu-main {
  width: 28px;
  height: 28px;
  margin-top: 10px;
  border: 0;
  padding: 0;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;

  span {
    display: block;
    height: 3px;
    width: 100%;
    background: rgb(84, 89, 95);
    opacity: 1;
    left: 0px;
    transition: all 0.25s ease-in-out 0s;
  }

  &.open {
    span:nth-child(1) {
      animation: rotate45Deg 0.2s;
      transform: translateY(5px) rotate(45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      animation: rotate135Deg 0.2s;
      transform: translateY(-11px) rotate(-45deg);
    }
  }
}

.mobile-sidebar {
  background-color: rgba(255, 255, 255, 0.97);
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: end;
  transition: all 0.25s;

  aside {
    width: 50%;
    height: 100vh;
    background: #fff;
    animation: slideFromRight 0.25s;
    box-shadow: 0 3px 12px rgb(0 0 0 / 7%);
    position: relative;
  }

  @media (max-width: 900px) {
    aside {
      width: 70%;
    }
  }

  @media (max-width: 700px) {
    aside {
      width: 100%;
    }
  }

  &__username {
    padding: 1.5rem;
  }

  &__navbar {
    border: 1px solid $color-telus-greyMystic;

    &__list {
      &__item {
        padding: 1.5rem;
        &:not(:last-child) {
          border-bottom: 1px solid $color-telus-greyMystic;
        }
      }
    }
  }
}
.locale-box {
  padding: 1rem 0 0 0;
}
