.time-entry-top-bar {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }

  &__left-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  &__right-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__nav-buttons {
      align-self: flex-end;
      display: flex;
      gap: 2rem;
    }
  }
}

.time-entry-table {
  &__date-column-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__select-box {
    border: 1px solid $color-telus-greyThunder;
    border-radius: 4px;
    width: 130px;
    height: 35px;
    padding: 0 4px;
  }

  &__input-box {
    border: 1px solid $color-telus-greyThunder;
    border-radius: 4px;
    width: 70px;
    height: 35px;
    padding: 6px;
  }

  .invalid {
    border: 2px solid $color-telus-redDark;
  }
}

.time-entry-action-bar {
  display: flex;
  justify-content: flex-end;
}
