.multiselect-filter {
  max-width: 500px;
  padding: 2.5rem;
  background-color: $color-telus-white;
  border: 1px solid $color-telus-greyMystic;
  border-radius: 4px;
  margin-top: 0.5rem;
  box-shadow: 1px 2px 6px $color-telus-greyMystic;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__items-box {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 0.75rem;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
}

.checkbox-container {
  margin-bottom: 8px;
  display: flex;
  input {
    width: 20px; /* Example width */
    height: 20px; /* Example height */
    margin-right: 8px; /* Adjust the spacing between the checkbox and label */
    background-color: #92afc2; /* Your custom background color */
    border-radius: 4px; /* Optional: Add border-radius for rounded corners */
    border: 1px solid #dfeef7; /* Optional: Add border for styling */
  }
}
