.flex-container {
  display: flex;
  align-items: center;

  span {
    margin-left: 12px;
  }
}

.custom-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 24px;
  height: 24px;
}

.custom-checkbox input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.custom-checkbox label {
  flex: 0 0 auto;
  position: relative;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid $color-telus-purpleTelus;
  border-radius: 5px;
  cursor: pointer;
}

.custom-checkbox input:checked + label {
  background-color: $color-telus-purpleTelus;
}

.custom-checkbox label:after {
  content: '';
  left: 6px;
  top: 0px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
}

.custom-checkbox input:checked + label:after {
  display: block;
}
