.login-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 85vh;
  border-radius: 4px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.78),
      rgba(255, 255, 255, 0.93)
    ),
    url('../../images/login_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.97);
    padding: 4rem 2rem;
    border-radius: 6px;
    border: 1px solid $color-telus-greyMystic;
    box-shadow: 0 2px 9px $color-telus-greyCloud;

    &__logo {
      width: 20rem;
      margin-left: 5rem;
    }

    &__signin-box {
      border: 1px solid $color-telus-greyMystic;
      border-radius: 4px;
      width: 80%;

      &__header-block {
        background-color: $color-telus-greyAthens;
        padding: 1rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
      }

      &__button-block {
        padding: 4rem 1rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login-container__inner {
    max-width: 100%;

    &__logo {
      width: 16rem;
    }

    &__signin-box {
      width: 100%;
    }
  }
}
