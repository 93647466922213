.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1.5rem;
}
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.notification-button-wrapper {
  border: 0;
  cursor: pointer;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-direction-vertical {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.gap-0-5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-1-5 {
  gap: 1.5rem;
}

.gap-2 {
  gap: 2rem;
}

.breadcrumbs-main {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & a {
    color: $color-telus-greyShuttle;
    text-decoration: underline;
  }
}

.edit-box-main {
  display: flex;
  flex-direction: column;

  input {
    background-color: $color-telus-white;
    color: #414547;
    border: 1px solid rgb(103, 110, 115);
    border-radius: 4px;
    padding: 11px 15px;
    font-size: 16px;
    line-height: 24px;
    font-family: HelveticaNow400normal;
    outline: none;
    resize: none;

    &:focus {
      border: 3px inset $color-telus-purpleDeluge;
    }

    &.error {
      border: 2px solid $color-telus-redDark;

      &:focus {
        border: 3px inset $color-telus-redDark;
      }
    }
  }
}
